.image-capture-container {
  margin: 10px 0;
}

.camera-container {
  width: 100%;
  height: 260px;
  overflow: hidden;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #000;
}

.camera-feed {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.preview-container {
  width: 100%;
  height: 100%;
}

.preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.button-container {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 10px;
}

.capture-button,
.upload-button,
.retake-button,
.view-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  background-color: #007bff;
  color: white;
}

.retake-button {
  background-color: #6c757d;
}

.hidden-input {
  display: none;
}

.image-viewer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.image-viewer-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
}

.close-button {
  position: absolute;
  top: 30px;
  right: 30px;
  background-color: rgba(0, 0, 0, 0.1); /* Light transparent background */
  border: none;
  border-radius: 50%; /* Round the button */
  font-size: 20px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #000;
  transition: all 0.2s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow */
}

.close-button:hover {
  background-color: rgba(0, 0, 0, 0.2); /* Darker on hover */
  transform: scale(1.1); /* Slightly increase size on hover */
  color: #fff; /* White color on hover */
}

.viewer-image {
  max-width: 100%;
  max-height: calc(90vh - 40px);
  display: block;
}

/* Switch.css */
.switch {
  position: relative;
  display: inline-block;
  width: 40px; /* Reduced width */
  height: 24px; /* Reduced height */
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 12px; /* Adjusted rounded corners */
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px; /* Adjusted circle height */
  width: 20px; /* Adjusted circle width */
  left: 2px;
  bottom: 2px;
  background-color: white;
  border-radius: 50%;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:checked + .slider:before {
  transform: translateX(16px); /* Adjusted translation */
}

body {
  background-color: #001f3f;
  color: white;
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.loading,
.progress-dialog,
.custom-dialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  color: white;
}

.responsive-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  background-color: #003366;
  padding: 20px;
  border-radius: 10px;

  box-sizing: border-box;
}

.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  flex-wrap: wrap;
}

.form-group label {
  font-size: 16px;
  font-weight: bolder;
  color: #ddd;
  margin-right: 10px;
  min-width: 130px;
  text-align: right;
}

.form-group input,
.form-group textarea,
.form-group select {
  flex: 1;
  padding: 12px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #004080;
  color: white;
  box-sizing: border-box;
}

.switch-container {
  margin-bottom: 5px;
  display: flex;
  flex-wrap: wrap; /* Allows wrapping */
  gap: 15px; /* Space between switch groups */
}

.switch-group {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  gap: 15px; /* Space between label and switch */
  width: calc(50% - 7.5px); /* Each switch group takes up half the width */
}

/* Align the first switch button of each row with the input fields */
.switch-group:first-child {
  margin-left: 0; /* Remove left margin for the first switch in the first row */
}

.switch-group:nth-child(3) {
  margin-left: 0; /* Remove left margin for the first switch in the second row */
}

button {
  padding: 10px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  background-color: #0056b3;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
  box-sizing: border-box;
}

button:hover {
  background-color: #0066cc;
}

.capture-container {
  margin-top: 5px;
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  box-sizing: border-box;
}

.recording-controls {
  margin-top: 15px;
}

.record-button {
  background-color: #28a745;
}

.record-button.recording {
  background-color: #dc3545;
}

.success-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}

@media (max-width: 600px) {
  body {
    margin: 0;
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .App {
    display: flex;
    flex-direction: column;
    height: 78vh; /* Full viewport height */
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
  }

  .responsive-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: space-between; /* Spreads items evenly */
    padding: 5px;
    box-sizing: border-box;
  }

  .form-group {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 1px;
    align-items: center;
    flex-grow: 1; /* Ensures it takes available space */
  }

  .form-group label {
    flex-basis: 30%;
    font-size: 12px;
    margin-bottom: 4px; /* Add space between label and input */
    text-align: right;
  }

  .form-group input,
  .form-group select,
  .form-group textarea {
    font-size: 16px;
    padding: 8px;
    width: 100%;
    box-sizing: border-box;
    flex-grow: 1; /* Adjust space based on visibility */
  }

  .switch-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 1px;
  }

  .switch-group {
    flex: 1 1 45%;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 4px;
    box-sizing: border-box;
  }

  button {
    width: 100%;
    height: 40px;
    font-size: 14px;
  }

  .capture-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 5px;
  }

  .recording-controls {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
  }
  /*
  .safe-area {
    padding: 5vmin;               5% of the viewport’s minimum dimension 
    overflow: hidden;
    background-color: #f2f2f2;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  */

  .form-group input::placeholder,
  .form-group select::placeholder,
  .form-group textarea::placeholder {
    color: white;
    opacity: 1;
  }
}

/* Ensure the confirm-popup is centered on all screen sizes */
.confirm-popup {
  background-color: #003366;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  position: fixed;
  width: 100%;
  max-width: 500px;
  height: auto;
}

/* Adjust form width and height within the popup */
.confirm-popup .responsive-form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

/* Center the header text */
.confirm-popup h3 {
  text-align: center;
  color: #fff;
}

/* Make form inputs more responsive */
.confirm-popup .form-group {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}


.confirm-popup .form-group label {
  font-size: 14px;
  color: #ddd;
  font-weight: bold;
  margin-bottom: 5px;
  text-align: left;
  width: 100%;
}

.confirm-popup .form-group input,
.confirm-popup .form-group select,
.confirm-popup .form-group textarea {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  color: black;
  box-sizing: border-box;
}

/* Button Group Styling */
.confirm-popup .button-group {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.confirm-popup button {
  padding: 6px 12px;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  width: 100px; /* Fixed button width */
}

/* Cancel and Submit Buttons */
.confirm-popup .cancel-button {
  background-color: #d3d3d3;
  color: black;
}

.confirm-popup .submit-button {
  background-color: #007bff;
  color: white;
}

.confirm-popup .submit-button[disabled] {
  background-color: #b3d7ff;
  cursor: not-allowed;
}

/* Ensure no overflow on mobile */
@media (max-width: 600px) {
  .confirm-popup {
    width: 90%; /* Ensure it fits on smaller screens */
    max-width: 400px; /* Limit the max width */
  }

  .confirm-popup .form-group label {
    font-size: 12px;
    min-width: 80px; /* Adjust for smaller screens */
  }

  .confirm-popup .form-group input,
  .confirm-popup .form-group select,
  .confirm-popup .form-group textarea {
    font-size: 16px;
    padding: 8px;
  }

  .confirm-popup .button-group {
    flex-direction: column; /* Stack buttons on small screens */
  }

  .confirm-popup button {
    width: 100%; /* Ensure buttons span full width on small screens */
  }
}


